import { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';

import { MetaProps } from './Meta';

const WHITE_LIST: RegExp[] = [/^\/admin/, /^\/trouble/];

export const InvalidMetaWarning = (props: PropsWithChildren<MetaProps>) => {
  const { pathname } = useRouter();

  if (
    process.env.NODE_ENV === 'development' &&
    props.pageProps.GET_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS !== true &&
    !WHITE_LIST.some(regex => regex.test(pathname))
  ) {
    throw new Error(
      '페이지 컴포넌트에 GetDefaultStaticProps 또는 GetDefaultServerSideProps를 사용하세요!',
    );
  }

  return props.children;
};
