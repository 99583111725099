import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LOCALES_ARR } from '@zep/consts/localize';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@zep/ui';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';

export const LanguageSwitch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger
        className="hidden h-[40px] max-w-[140px] items-center overflow-hidden whitespace-nowrap rounded-[8px] py-[10px] pl-xs pr-2xs text-[14px] focus:outline-none md:flex"
        tabIndex={-1}>
        🌐
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="shadow-[0_4px_8px_2px_rgba(28, 27, 31, 0.12)] min-w-[148px] rounded-[8px] px-[8px] py-3xs text-[13px]">
        <DropdownMenuGroup>
          {SUPPORTED_LOCALES_ARR.map(locale => (
            <DropdownMenuItem
              key={locale}
              onClick={() => {
                router.push(router.asPath, undefined, { locale });
              }}
              className={cn('cursor-pointer p-xs text-body-sm font-medium')}>
              {locale} {t(`common.language.${locale}`)}{' '}
              {locale === 'ko' && '| 노운 이슈: 외국 브라우저에서 접속 X'}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
