/** 언어가 추가되는 변수입니다.
 * ./localize-js.js 에도 언어를 추가해야 합니다.
 */
export const SUPPORTED_LOCALES_OBJ = {
  ko: 'ko',
  en: 'en',
  ja: 'ja',
  id: 'id',
  th: 'th',
  fil: 'fil',
  vi: 'vi',
  ms: 'ms',
  uz: 'uz',
  fr: 'fr',
  km: 'km',
  ru: 'ru',
  'zh-Hans': 'zh-Hans',
} as const;
export const SUPPORTED_LOCALES_ARR = Object.values(SUPPORTED_LOCALES_OBJ);

export type SupportedLocalesObject = typeof SUPPORTED_LOCALES_OBJ;
export type SupportedLocalesArray = typeof SUPPORTED_LOCALES_ARR;

export type SupportedLocale = SupportedLocalesArray[number];

export type SupportedLocaleData<
  T extends SupportedLocale = SupportedLocale,
  U = string,
> = Record<T, U> & Partial<Record<Exclude<SupportedLocale, T>, U>>;

export const SUPPORTED_COUNTRY_CODES_ARR = ['KR', 'US', 'JP'] as const;
export type SupportedCountryCode = (typeof SUPPORTED_COUNTRY_CODES_ARR)[number];
export function isSupportedCountryCode(
  code: string,
): code is SupportedCountryCode {
  return SUPPORTED_COUNTRY_CODES_ARR.includes(code as any);
}
