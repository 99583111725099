import { SUPPORTED_LOCALES_OBJ, SupportedLocale } from '@zep/consts/localize';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export const useLocale = () => {
  const { locale = SUPPORTED_LOCALES_OBJ.en, locales = [] } = useRouter();
  // TODO: check unsupported language
  const { i18n } = useTranslation();
  console.log(locale, i18n.resolvedLanguage);

  return {
    locale: locale as SupportedLocale,
    locales: locales as SupportedLocale[],
  };
};
