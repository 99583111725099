import { useLocale } from '@zep/hooks';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SSRConfig } from 'next-i18next';

import { SeoProperties } from '../../types';
import {
  GET_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS_KEY,
  isDevelopment,
} from '../../utils';

import { InvalidMetaWarning } from './InvalidMetaWarning';

const getTitle = (title: string, postTitle: string) =>
  `${title || 'ZEP QUIZ'} | ${postTitle}`;

export const Meta = ({ pageProps }: MetaProps) => {
  const {
    _nextI18Next,
    seoContent: partialSeoContent,
    noIndex = false,
  } = pageProps;
  const { asPath } = useRouter();
  const { locale, locales } = useLocale();

  const defaultPostTitle =
    _nextI18Next?.initialI18nStore[locale].common['seo.default.postTitle'] ??
    '';
  const title = getTitle(partialSeoContent?.title ?? '', defaultPostTitle);

  const defaultDescription =
    _nextI18Next?.initialI18nStore[locale].common['seo.default.description'] ??
    '';

  const defaultImage = isDevelopment()
    ? 'https://dev-quiz.zep.us/assets/ogimage.png'
    : 'https://quiz.zep.us/assets/ogimage.png';

  const seoContent = {
    title,
    subject: partialSeoContent?.subject ?? title,
    description: partialSeoContent?.description ?? defaultDescription,
    image: partialSeoContent?.image ?? defaultImage,
    keywords:
      partialSeoContent?.keywords ??
      'ZEP, metaverse, zep quiz, meta, quiz, 젭, 젭퀴즈, 퀴즈 제작, 퀴즈 만들기, 방탈출퀴즈',
    copyright: partialSeoContent?.copyright ?? 'ZEP Inc',
    author: partialSeoContent?.author ?? '',
    schema: {
      image: defaultImage,
      ...partialSeoContent?.schema,
    } as Record<string, any>,
  };

  return (
    <InvalidMetaWarning pageProps={pageProps}>
      <Head>
        {locale === 'ko' && !noIndex && (
          <link
            rel="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`}
          />
        )}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`}
        />
        {locales.map(lang => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${
              lang === 'ko' ? '' : `/${lang}`
            }${asPath}`}
          />
        ))}
        <link
          rel="icon"
          type="image/svg+xml"
          href="/assets/ZEPQuiz-favicon.svg"
        />
        {isDevelopment() || noIndex ? (
          <>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
          </>
        ) : (
          <>
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
          </>
        )}
        <title>{seoContent.title}</title>
        <meta name="title" content={seoContent.title} />
        <meta name="description" content={seoContent.description} />
        <meta name="subject" content={seoContent.subject} />
        <meta name="keywords" content={seoContent.keywords} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={seoContent.title} />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}/${locale}${asPath}`}
        />
        <meta property="og:title" content={seoContent.title} />
        <meta property="og:description" content={seoContent.description} />
        <meta property="og:image" content={seoContent.image} />
        <meta property="og:locale" content={locale} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={seoContent.title} />
        <meta property="twitter:description" content={seoContent.description} />
        <meta property="twitter:image" content={seoContent.image} />
        <meta name="application-name" content="ZEP Quiz" />
        <meta name="copyright" content={seoContent.copyright} />
        <meta name="author" content={seoContent.author} />
        <meta name="reply-to" content="hello@zep.us" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {seoContent.schema['@context'] && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(seoContent.schema),
            }}
          />
        )}
      </Head>
    </InvalidMetaWarning>
  );
};

export type MetaProps = {
  pageProps: {
    seoContent: Partial<SeoProperties>;
    noIndex?: boolean;
    [GET_DEFAULT_STATIC_OR_SERVER_SIDE_PROPS_KEY]?: boolean;
  } & SSRConfig;
};
