import { SupportedLocaleData } from '@zep/consts/localize';

import { BannerType } from './StickyBanner';

// 우선 한국어만 배너 변경 진행
export const BANNER_DATA: SupportedLocaleData<
  'ko' | 'en' | 'ja',
  Partial<
    Record<
      BannerType,
      { imgSrc: string; linkHref: string; loginRequired: boolean }
    >
  >
> = {
  ko: {
    DEFAULT: {
      imgSrc: '/assets/sticky_banner_5_ko.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
    REVIEW: {
      imgSrc: '/assets/sticky_banner_review_ko.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ko/articles/f8f2f82d-ZEP-QUIZ-%ED%9B%84%EA%B8%B0-%EC%9D%B4%EB%B2%A4%ED%8A%B82',
      loginRequired: false,
    },
  },
  en: {
    DEFAULT: {
      imgSrc: '/assets/sticky_banner_5_en.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/en/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
  },
  ja: {
    DEFAULT: {
      imgSrc: '/assets/sticky_banner_5_ja.webp',
      linkHref:
        'https://docs.channel.io/zepquiz/ja/articles/5e8edb41-ZEP-QUIZ-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9D%B4%EB%B2%A4%ED%8A%B8-%EC%95%88%EB%82%B4',
      loginRequired: true,
    },
  },
};

export const BANNER_CLASSNAMES: Record<BannerType, string> = {
  DEFAULT: 'bg-gradient-to-l from-[#AF70FF] to-[#3DA9FF]',
  REVIEW: 'bg-gradient-to-r from-[#3BC3B1] via-[#1EC2A9] to-[#4ABC56]',
};
