import {
  ComponentPropsWithoutRef,
  CSSProperties,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  ReactEventHandler,
} from 'react';
import { useIsMounted } from '@toss/react';
import { safeLocalStorage } from '@toss/storage';
import {
  HIDE_BANNER_COOKIE_KEY,
  LAST_BANNER_LOCAL_STORAGE_KEY,
} from '@zep/consts/keys';
import { useBody, useBodyAttribute, useLocale } from '@zep/hooks';
import { IconContainer, XIcon } from '@zep/icons';
import { useUser } from '@zep/lib/auth';
import { cn } from '@zep/utils';
import { zepAnalytics } from '@zep/utils/analytics';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';

import { BANNER_CLASSNAMES, BANNER_DATA } from './StickyBanner.const';
import styles from './StickyBanner.module.scss';
import { useBannerType } from './useBannerType';

// 24.11.27 기준, DEFAULT의 내용은 "2주 무료체험" 입니다.
export type BannerType = 'DEFAULT' | 'REVIEW';

const transformBannerTypeForAnalytics = (bannerType: BannerType) => {
  return bannerType === 'DEFAULT' ? '2_weeks_free_trial' : 'review';
};

const PathAliasMap: Record<string, string> = {
  '/': 'home',
  '/public': 'public',
  '/search': 'search',
  '/quiz': 'listItem',
  '/quiz/[id]': 'detail',
  '/build': 'create',
  '/update/[id]': 'update',
  '/duplicate/[id]': 'duplicate',
  unknown: 'unknown',
};

export const StickyBanner = () => {
  const { pathname } = useRouter();
  const { isAuthenticated } = useUser();
  const { locale } = useLocale();
  const isMounted = useIsMounted();
  const isHide = getCookie(HIDE_BANNER_COOKIE_KEY) === 'true';

  const { isLoadingBannerType, newBannerType } = useBannerType();

  const goCTALink = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const link = (BANNER_DATA[locale] ?? BANNER_DATA.en)[newBannerType]
      ?.linkHref;

    zepAnalytics.track('banner_click', {
      location: PathAliasMap[String(pathname)] || PathAliasMap.unknown,
      bannerType: transformBannerTypeForAnalytics(newBannerType),
    });
    if ((BANNER_DATA[locale] ?? BANNER_DATA.en)[newBannerType]?.loginRequired) {
      window.open(isAuthenticated ? link : '/login', '_blank');
    } else {
      window.open(link, '_blank');
    }
  };

  const hideBanner = (e: MouseEvent) => {
    e.stopPropagation();
    setCookie(HIDE_BANNER_COOKIE_KEY, true, {
      maxAge: 10 * 60,
    });
    document.body.setAttribute('data-is-stick-banner-show', 'false');
    safeLocalStorage.remove(LAST_BANNER_LOCAL_STORAGE_KEY);
  };

  useBody(
    {
      attributes: [
        {
          name: 'data-is-stick-banner-show',
          value: isHide ? 'false' : 'true',
        },
      ],
    },
    [isHide],
  );

  const shouldShowBanner =
    useBodyAttribute('data-is-stick-banner-show') === 'true';

  return (
    <Wrapper
      className={cn({
        [BANNER_CLASSNAMES[newBannerType]]: !isLoadingBannerType,
        invisible: !isMounted || !shouldShowBanner,
      })}
      onClick={goCTALink}>
      <Container>
        <CTA
          src={
            isLoadingBannerType
              ? undefined
              : (BANNER_DATA[locale] ?? BANNER_DATA.en)[newBannerType]?.imgSrc
          }
        />
        <div className={'absolute right-0'}>
          <IconContainer onClick={hideBanner}>
            <XIcon
              style={{ position: 'absolute', right: 18 }}
              fill={'#FFF'}
              width={24}
              height={24}
            />
          </IconContainer>
        </div>
      </Container>
    </Wrapper>
  );
};

const Container = (props: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={cn(
        'w-full min-[561px]:w-[90%] relative flex max-w-[960px] items-center justify-center',
      )}>
      {props.children}
    </div>
  );
};

const CTA = (props: {
  src?: string;
  onLoad?: ReactEventHandler<HTMLImageElement>;
}) => {
  return (
    <picture
      className={cn(
        'cursor-pointer',
        'flex size-full items-center justify-center',
        'min-[561px]:absolute min-[561px]:w-[624px]',
      )}>
      <img
        className={'max-h-[64px] min-[561px]:max-h-[80px]'}
        srcSet={props.src}
        alt={'banner'}
        onLoad={props.onLoad}
      />
    </picture>
  );
};

const Wrapper = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  (props, ref) => {
    const { className, style, ...rest } = props;
    return (
      <div
        ref={ref}
        className={cn(
          'fixed cursor-pointer flex items-center justify-center top-0 z-[50] ',
          'w-max-[960px]  top-0 z-[50] flex h-[64px] w-full',
          'min-[561px]:h-[80px] min-[561px]:w-[100vw] min-[561px]:gap-[135px]',
          className,
        )}
        style={{
          ...style,
        }}
        {...rest}
      />
    );
  },
);

Wrapper.displayName = 'ContainerWrapper';

export const BannerSpacer = (
  props: PropsWithChildren<{
    headerHeight: {
      mobile: number;
      normal: number;
    };
    className?: string;
  }>,
) => {
  return (
    <div
      className={cn(styles.wrapper, props.className)}
      style={
        {
          '--header-height': props.headerHeight.normal + 'px',
          '--header-mobile-height': props.headerHeight.mobile + 'px',
        } as CSSProperties
      }>
      {props.children}
    </div>
  );
};
