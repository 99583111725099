import { useEffect, useRef, useState } from 'react';
import { safeLocalStorage } from '@toss/storage';
import { LAST_BANNER_LOCAL_STORAGE_KEY } from '@zep/consts/keys';
import { SupportedLocale, SupportedLocaleData } from '@zep/consts/localize';
import { useEffectOnce, useLocale } from '@zep/hooks';

import { BannerType } from './StickyBanner';

const BANNER_ROTATION: SupportedLocaleData<
  'ko' | 'en' | 'ja',
  Partial<Record<BannerType, BannerType>>
> = {
  ko: {
    DEFAULT: 'REVIEW',
    REVIEW: 'DEFAULT',
  },
  en: {
    DEFAULT: 'DEFAULT',
  },
  ja: {
    DEFAULT: 'DEFAULT',
  },
};

function getNewBannerType(
  lastBannerType: string | null,
  locale: SupportedLocale,
): BannerType {
  if (lastBannerType === null) return 'DEFAULT';
  return (
    (BANNER_ROTATION[locale] ?? BANNER_ROTATION.en)[
      lastBannerType as BannerType
    ] ?? 'DEFAULT'
  );
}

export const useBannerType = () => {
  const { locale } = useLocale();
  const [isLoadingBannerType, setisLoadingBannerType] = useState(true);
  const lastBannerTypeRef = useRef<string | null>(null);
  const newBannerType = getNewBannerType(lastBannerTypeRef.current, locale);

  useEffectOnce(() => {
    lastBannerTypeRef.current = safeLocalStorage.get(
      LAST_BANNER_LOCAL_STORAGE_KEY,
    );
    setisLoadingBannerType(false);
  });

  useEffect(() => {
    // 우선 한국어만 배너 변경 진행
    if (locale !== 'ko') return;
    if (isLoadingBannerType) return;
    safeLocalStorage.set(LAST_BANNER_LOCAL_STORAGE_KEY, newBannerType);
  }, [locale, isLoadingBannerType, newBannerType]);

  return {
    isLoadingBannerType,
    newBannerType,
  };
};
